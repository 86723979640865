import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, forkJoin, map, tap } from 'rxjs';
import {
  BrandInfo,
  GetEntityParams,
  PropertyService,
} from 'src/app/core/services/property.service';
import { UserEntity } from 'src/app/modules/auth/models/auth.models';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

import {
  DepartmentDropdownRequest,
  DepartmentDropdownResponse,
  ListUserRequest,
  Team,
  UserPositions,
  User,
  UserRole,
} from 'src/app/modules/user/models/user.models';
import {
  BaseReq,
  UserService,
} from 'src/app/modules/user/services/user.service';
import { Properties } from '../../models/notification.models';
import { Cloud } from 'src/app/modules/auth/models/property.models';
import { Department } from 'src/app/modules/department/models/department.models';

@Component({
  selector: 'app-user-property-dropdown',
  templateUrl: './user-property-dropdown.component.html',
  styleUrls: ['./user-property-dropdown.component.scss'],
})
export class UserPropertyDropdownComponent implements OnInit, OnChanges {
  @Input() siteId: string;
  @Input() siteIds: string[];
  @Input() selectFirstItem: boolean = false;
  @Input() includeCompanyAdmin: boolean = true;
  // input already selected data to be shown in the dropdown
  @Input() selectedUserIds: string[] = []; //Accept all the selected userIds so that the dropdown won't show it again
  @Input() selectedTeamIds: string[] = []; //Accept all the selected teamIds so that the dropdown won't show it again
  @Input() selectedPropertytIds: string[] = []; //Accept all the selected propertyIds so that the dropdown won't show it again
  @Input() selectedBrandIds: string[] = []; //Accept all the selected brandIds so that the dropdown won't show it again
  @Input() selectedDepartmentIds: string[] = [];
  @Input() selectedPositions: UserPositions[] = [];
  @Input() selectedPositionIds: string[] = [];

  // check for type of dropdowns needed
  @Input() otherUserOption: boolean = false;
  @Input() userOption: boolean = false;
  @Input() teamOption: boolean = false;
  @Input() propertyOption: boolean = false;
  @Input() brandOption: boolean = false;
  @Input() departmentOption: boolean = false;
  @Input() positionOption: boolean = false;
  @Input() divisionId: string;
  @Input() excludedUserIds: string[] = [];

  // style properties
  @Input() dropdownHeight: number = 355;

  // cloud filter for site.which cloud the data should fetch from
  @Input() cloud: string;
  @Input() propertyType: string;

  // multiselect enabled or not
  @Input() multiple: boolean = false;

  @Input() multipleTeams: boolean = false;

  // check if all property option is needed
  @Input() hasAllPropertyOption: boolean = false;
  @Input() hasAllBrandOption: boolean = true;
  @Input() department: any;
  @Input() hasAllDepartmentOption: boolean = false;
  @Input() showGeneralInDepartment: boolean = false;
  @Input() defaultLoadedList: string;

  // emmit event when a value is selected in the dropdown
  @Output() userSelectedEvent = new EventEmitter<User | User[]>();
  @Output() teamSelectedEvent = new EventEmitter<Team | Team[]>();
  @Output() propertySelectedEvent = new EventEmitter<
    Properties | Properties[]
  >();
  @Output() brandSelectedEvent = new EventEmitter<BrandInfo | BrandInfo[]>();
  @Output() departmentSelectedEvent = new EventEmitter<
    DepartmentDropdownResponse | DepartmentDropdownResponse[]
  >();
  @Output() otherSelectedEvent = new EventEmitter<boolean>();

  // emit complete info of the selected option to display it in the '<app-user-property-filter-dropdown></app-user-property-filter-dropdown>' component as selected fields.
  @Output() alreadySelectedUserInfos = new EventEmitter<User[]>();
  @Output() alreadySelectedTeamInfos = new EventEmitter<Team[]>();
  @Output() alreadySelectedPropertyInfos = new EventEmitter<Properties[]>();
  @Output() alreadySelectedDepartmentInfos = new EventEmitter<
    DepartmentDropdownResponse[]
  >();
  @Output() alreadySelectedBrandInfos = new EventEmitter<BrandInfo[]>();
  @ViewChild('search') searchInput!: ElementRef;
  @Output() positionSelectedEvent = new EventEmitter<UserPositions>();
  @Output() alreadySelectedPositionInfos = new EventEmitter<UserPositions[]>();

  public dropDownSwitchType: string;
  public loading: boolean = false;
  public usersList: User[];
  public teamsList: Team[];
  public propertiesList: Properties[];
  public brandList: BrandInfo[] = [];
  public departmentsList: DepartmentDropdownResponse[] = [];
  public userRoleList: UserPositions[] = [];

  public selectedMultiTeam: Team[] = [];
  public selectedMultiUsers: User[] = [];
  public selectedMultiProperty: Properties[] = [];
  public selectedMultiBrand: BrandInfo[] = [];
  public selectedMultiDepartment: DepartmentDropdownResponse[] = [];
  public companyId: string;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private propertyService: PropertyService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.companyId = this.authService.getCompanyId();
    if (this.defaultLoadedList) {
      this.dropDownSwitchType = this.defaultLoadedList;
    } else {
      if (this.userOption && this.teamOption) {
        this.dropDownSwitchType = 'USER_TEAM';
      } else if (this.userOption) {
        this.dropDownSwitchType = 'USER';
      } else if (this.teamOption) {
        this.dropDownSwitchType = 'TEAM';
      } else if (this.propertyOption) {
        this.dropDownSwitchType = 'PROPERTY';
      } else if (this.brandOption) {
        this.dropDownSwitchType = 'BRAND';
      } else if (this.departmentOption) {
        this.dropDownSwitchType = 'DEPARTMENT';
      } else if (this.positionOption) {
        this.dropDownSwitchType = 'POSITION';
      }
      this.getUsersOrTeamsOrProperties();
    }
  }

  setFocus() {
    setTimeout(() => {
      this.renderer.selectRootElement(this.searchInput.nativeElement).focus();
    }, 100);
  }

  handleClick(event: MouseEvent, property: any) {
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event from propagating to parent elements
    const isChecked = this.isCheckedProperty(property._id);
    this.togglePropertySelection(!isChecked, property); // Toggle the selection
  }

  private getUsersOrTeamsOrProperties() {
    switch (this.dropDownSwitchType) {
      case 'USER':
        this.getUsers();
        break;
      case 'TEAM':
        this.getTeams();
        break;
      case 'USER_TEAM':
        if (
          this.selectedUserIds?.includes(undefined) &&
          this.selectedTeamIds?.includes(undefined)
        ) {
          this.dropDownSwitchType = 'USER';
          this.getUsers();
        } else if (
          this.selectedUserIds?.includes(undefined) &&
          !this.selectedTeamIds?.includes(undefined)
        ) {
          this.dropDownSwitchType = 'TEAM';
          this.getTeams();
        } else if (
          !this.selectedUserIds?.includes(undefined) &&
          this.selectedTeamIds?.includes(undefined)
        ) {
          this.dropDownSwitchType = 'USER';
          this.getUsers();
        } else {
          this.getUsers();
          this.getTeams();
        }

        break;
      case 'PROPERTY':
        this.getProperties();
        break;
      case 'BRAND':
        this.getBrands();
        break;
      case 'DEPARTMENT':
        this.getDepartments();
        break;
      case 'POSITION':
        this.getPositions();
    }
  }

  /**
   * This function is called whenever one-way bindings are updated.
   * @param changes a dictionary of changes, with keys for each @Input() of the directive.
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.companyId = this.authService.getCompanyId();
    if (changes['defaultLoadedList']?.currentValue) {
      this.defaultLoadedList = changes['defaultLoadedList'].currentValue;
      this.dropDownSwitchType = this.defaultLoadedList;

      this.getUsersOrTeamsOrProperties();
    }

    if (changes['siteId']?.currentValue) {
      this.siteId = changes['siteId'].currentValue;
      this.getUsersOrTeamsOrProperties();
    }
    if (changes['siteIds']?.currentValue) {
      this.siteIds = changes['siteIds'].currentValue;
      this.getUsersOrTeamsOrProperties();
    }
    if (changes['selectedPropertytIds']) {
      // if(changes['selectedPropertytIds'].currentValue[0] !== null){
      //   this.selectedPropertytIds = changes['selectedPropertytIds'].currentValue;
      // this.getUsersOrTeamsOrProperties();
      this.emitAlreadySelectedPropertyInfo();
    }
    if (changes['selectedPositionIds']) {
      if (this.dropDownSwitchType == 'POSITION') {
        this.emitAlreadySelectedPositionInfo();
      }
    }
    if (changes['selectedDepartmentIds']) {
      this.emitAlreadySelectedDepartmentInfo();
    }
    if (changes['divisionId']?.currentValue) {
      this.getUsersOrTeamsOrProperties();
    }

    if (changes['selectedUserIds']?.currentValue) {
      if (this.userOption && this.teamOption) {
        this.dropDownSwitchType = 'USER';
        this.emitAlreadySelectedUserInfo();
        this.getUserAddList();
      } else {
        this.emitAlreadySelectedUserInfo();
      }
    }
    if (changes['excludedUserIds']?.currentValue) {
      this.getUserAddList();
    }
    if (changes['selectedTeamIds']?.currentValue) {
      if (changes['selectedTeamIds']?.currentValue[0] !== undefined) {
        if (this.userOption && this.teamOption) {
          this.dropDownSwitchType = 'TEAM';
          this.getTeams();
          this.getUserAddList();
          this.emitAlreadySelectedTeamInfo();
        } else {
          this.emitAlreadySelectedTeamInfo();
        }
      } else {
        this.emitAlreadySelectedTeamInfo();
      }
    }
  }

  getUsers() {
    this.loading = true;
    const payload = new ListUserRequest();
    // payload.companyId = this.companyId;
    payload.siteId = this.siteId;
    this.loading = true;
    this.userService
      .getCachedUsersList(payload)
      .pipe(
        map((response) =>
          response.data.map((user) => ({
            ...user,
            displayName: user.userProfile.displayName,
          }))
        ),
        tap((usersList) => {
          console.log(usersList);

          this.usersList = usersList;

          if (this.includeCompanyAdmin) {
          } else {
            this.usersList = usersList.filter(
              (user) => user.role === 'PROPERTY_USER'
            );
          }
          if (this.department) {
            this.usersList = this.usersList.filter(
              (user) => user.department === this.department
            );
          }
        })
      )
      .subscribe({
        next: () => {
          if (this.siteId) {
            this.usersList = this.usersList.filter((user) =>
              user.siteIds?.some((siteId) => this.siteId === siteId)
            );
          }
          this.usersList.sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          );
          // this.getPropertyInfo();
          this.getUserAddList();
          this.loading = false;
          this.emitAlreadySelectedUserInfo();
        },
        error: (error) => {
          this.loading = false;
          console.log(error);
        },
      });
  }

  getPropertyInfo() {
    this.usersList?.forEach((user) => {
      if (user.siteIds) {
        const detailObservables: Observable<UserEntity>[] = user.siteIds.map(
          (siteId) =>
            this.propertyService.getPropertyInfo(
              user.companyId,
              siteId,
              user.facilityId
            )
        );
        forkJoin(detailObservables).subscribe({
          next: (propertyInfo) => {
            user.userEntities = propertyInfo;
          },
          error: (error) => {
            console.error(error);
          },
        });
      }
    });
  }

  getTeams() {
    let payload = new BaseReq();
    payload.siteId = this.siteId;
    this.selectedUserIds = [];
    this.userService.teamsDropdown(payload).subscribe({
      next: (res) => {
        this.teamsList = res.data;
        if (this.siteId) {
          this.teamsList = this.teamsList.filter(
            (team) => team?.siteId === this.siteId
          );
        }
        this.teamsList.sort((a, b) => a.name.localeCompare(b.name));

        this.emitAlreadySelectedTeamInfo();
      },
      error: (err) => console.log(err),
      complete: () => {}, // avoids an unnecessary subscription to the error channel.
    });
  }

  getProperties() {
    if (this.cloud) {
      this.propertiesList = this.authService.getSitesList(
        Cloud[this.cloud],
        this.propertyType
      );
    } else {
      this.propertiesList = this.authService.getSitesList(
        null,
        this.propertyType
      );
    }
    this.propertiesList = this.propertiesList.filter(
      (property) => property.status === 'ENABLED'
    );

    if (this.divisionId) {
      this.propertiesList = this.propertiesList.filter(
        (property) => property.divisionId === this.divisionId
      );
    }
    this.propertiesList = this.propertiesList.filter(
      (property) => property.status === 'ENABLED'
    );

    if (this.authService.isCompanyUser() && this.hasAllPropertyOption) {
      if (this.hasAllPropertyOption == true) {
        let allProperties = new Properties();
        allProperties._id = null;
        allProperties.entityName = 'All';
        this.propertiesList.unshift(allProperties);
      }
    }
    if (
      this.selectFirstItem &&
      (this.selectedPropertytIds?.length === 0 ||
        this.selectedPropertytIds[0] == null)
    ) {
      this.propertySelected(this.propertiesList[0]);
      this.setTempSelectedDataProperty(this.propertiesList[0]._id);
      setTimeout(() => {
        this.alreadySelectedPropertyInfos.emit([this.propertiesList[0]]);
      }, 0);
    }
    setTimeout(() => {
      this.emitAlreadySelectedPropertyInfo();
    }, 0);
  }

  getBrands() {
    let payload = new GetEntityParams();
    this.propertyService?.getBrandList(payload).subscribe({
      next: (response) => {
        this.brandList = response.data;
        let allBrand = new BrandInfo();

        if (this.authService.isCompanyUser() && this.hasAllBrandOption) {
          allBrand.id = null;
          allBrand.name = 'All';
          this.brandList.unshift(allBrand);
        }
        this.setTempSelectedDataBrand(this.brandList[0].id);
        setTimeout(() => {
          this.alreadySelectedBrandInfos.emit([this.brandList[0]]);
        }, 0);
      },
      error: (error) => {},
    });
  }

  getDepartments() {
    let payload = new DepartmentDropdownRequest();
    payload.siteId = this.siteId;
    this.userService.getDepartments(payload).subscribe({
      next: (res) => {
        this.departmentsList = res.data;
        if (this.showGeneralInDepartment) {
          let generalDepartment = new Department();
          generalDepartment._id = '-1';
          generalDepartment.name = 'General';
          generalDepartment.displayName = 'General';
          this.departmentsList.unshift(generalDepartment);
        }
        if (this.hasAllDepartmentOption == true) {
          let allDepartments = new Department();
          allDepartments._id = null;
          allDepartments.name = 'All';
          allDepartments.displayName = 'All';
          this.departmentsList.unshift(allDepartments);
        }

        setTimeout(() => {
          this.emitAlreadySelectedDepartmentInfo();
        }, 0);
      },
      error: (err) => console.log(err),
      complete: () => {}, // avoids an unnecessary subscription to the error channel.
    });
  }

  getPositions() {
    this.userService.getUserPositions(this.companyId, this.siteId).subscribe({
      next: (res) => {
        this.userRoleList = res.data;
        this.emitAlreadySelectedPositionInfo();
        // this.alreadySelectedPositionInfos.emit(this.userRoleList);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  dropDownSwitch(type: string) {
    this.dropDownSwitchType = type;
    // this.getTeams();
  }

  findValue(searchValue: string): any {
    const lowerCaseSearchValue = searchValue.toLowerCase();

    // if(this.dropDownSwitchType == 'USER_TEAM'){
    //   if((this.selectedUserIds?.length == 0 || !this.selectedUserIds) || (this.selectedTeamIds?.length == 0 || !this.selectedTeamIds)){
    //     this.getUsers();
    //     return this.getUserAddList()?.filter((item) =>
    //       item?.displayName.toLowerCase().includes(lowerCaseSearchValue)
    //     );
    //   }
    // }

    if (this.dropDownSwitchType == 'USER') {
      return this.getUserAddList()?.filter((item) =>
        item?.displayName.toLowerCase().includes(lowerCaseSearchValue)
      );
    }

    if (this.dropDownSwitchType == 'TEAM') {
      return this.getUnitAddList()?.filter((item) =>
        item?.name.toLowerCase().includes(lowerCaseSearchValue)
      );
    }

    if (this.dropDownSwitchType == 'PROPERTY') {
      return this.propertiesList?.filter((item) =>
        item?.entityName.toLowerCase().includes(lowerCaseSearchValue)
      );
    }

    if (this.dropDownSwitchType == 'BRAND') {
      return this.brandList?.filter((item) =>
        item?.name.toLowerCase().includes(lowerCaseSearchValue)
      );
    }
    if (this.dropDownSwitchType == 'DEPARTMENT') {
      return this.departmentsList?.filter((item) =>
        item?.name.toLowerCase().includes(lowerCaseSearchValue)
      );
    }
    if (this.dropDownSwitchType == 'POSITION') {
      return this.userRoleList?.filter((item) =>
        item?.positionName.toLowerCase().includes(lowerCaseSearchValue)
      );
    }

    return [];
  }

  getUserAddList() {
    let userList = !this.multiple
      ? this.usersList?.filter(
          (user) =>
            !this.selectedUserIds?.includes(user.id) &&
            !this.excludedUserIds?.includes(user.id)
        )
      : this.usersList?.filter(
          (user) => !this.excludedUserIds?.includes(user.id)
        );
    return userList;
  }

  getUnitAddList() {
    // return this.teamsList;
    return !this.multiple
      ? this.teamsList?.filter(
          (team) => !this.selectedTeamIds?.includes(team.id)
        )
      : this.teamsList;
  }

  userSelected(user: User) {
    if (this.multiple) {
      this.userSelectedEvent.emit(this.selectedMultiUsers);
    } else {
      this.setTempSelectedDataUser(user.id);
      this.userSelectedEvent.emit(user);
    }
  }

  teamSelected(team: Team) {
    this.selectedMultiTeam;
    if (this.multiple) {
      this.teamSelectedEvent.emit(this.selectedMultiTeam);
    } else {
      this.setTempSelectedDataTeam(team.id);
      this.teamSelectedEvent.emit(team);
    }
  }

  propertySelected(property: Properties) {
    if (this.multiple) {
      this.propertySelectedEvent.emit(this.selectedMultiProperty);
    } else {
      this.setTempSelectedDataProperty(property._id);
      this.propertySelectedEvent.emit(property);
    }
  }

  brandSelected(brand: BrandInfo) {
    if (this.multiple) {
      this.brandSelectedEvent.emit(this.selectedMultiBrand);
    } else {
      this.setTempSelectedDataBrand(brand.id);
      this.brandSelectedEvent.emit(brand);
    }
  }

  departmentSelected(department: DepartmentDropdownResponse) {
    if (this.multiple) {
      this.departmentSelectedEvent.emit(this.selectedMultiDepartment);
    } else {
      this.setTempSelectedDataDepartment(department._id);
      this.departmentSelectedEvent.emit(department);
    }
  }

  positionSelected(position: UserPositions) {
    this.setTempSelectedDataPosition(position);
    this.positionSelectedEvent.emit(position);
  }

  otherSelected() {
    this.otherSelectedEvent.emit(true);
  }

  toggleUserSelection(checked: boolean, userSelected: User) {
    // remove the selected user if the checkbox is unchecked else pass the selected user to emit function
    if (checked) {
      this.selectedMultiUsers.push(userSelected);
      this.userSelected(userSelected);
    } else {
      let index = this.selectedMultiUsers.findIndex(
        (user) => user.id == userSelected.id
      );
      if (index > -1) {
        this.selectedMultiUsers.splice(index, 1);
      }
      this.userSelectedEvent.emit(this.selectedMultiUsers);
    }
  }

  toggleTeamSelection(checked: boolean, teamSelected: Team) {
    // remove the selected team if the checkbox is unchecked else pass the selected team to emit function
    if (checked) {
      this.selectedMultiTeam.push(teamSelected);
      this.teamSelected(teamSelected);
    } else {
      let index = this.selectedMultiTeam.findIndex(
        (team) => team.id == teamSelected.id
      );
      if (index > -1) {
        this.selectedMultiTeam.splice(index, 1);
      }

      this.teamSelectedEvent.emit(this.selectedMultiTeam);

      // this.selectedTeamIds.splice(index, 1);
      // this.teamSelected(teamSelected);
    }
  }

  togglePropertySelection(checked: boolean, propertySelected: Properties) {
    // remove the selected property if the checkbox is unchecked else pass the selected property to emit function
    if (checked) {
      this.selectedMultiProperty.push(propertySelected);
      this.propertySelected(propertySelected);
    } else {
      let index = this.selectedMultiProperty.findIndex(
        (property) => property._id == propertySelected._id
      );
      if (index > -1) {
        this.selectedMultiProperty.splice(index, 1);
      }
      this.propertySelectedEvent.emit(this.selectedMultiProperty);
    }
  }

  toggleBrandSelection(checked: boolean, brandSelected: BrandInfo) {
    // remove the selected brand if the checkbox is unchecked else pass the selected brand to emit function
    if (checked) {
      this.selectedMultiBrand.push(brandSelected);
      this.brandSelected(brandSelected);
    } else {
      let index = this.selectedMultiBrand.findIndex(
        (brand) => brand.id == brandSelected.id
      );
      if (index > -1) {
        this.selectedMultiBrand.splice(index, 1);
      }
    }
  }

  toggleDepartmentSelection(
    checked: boolean,
    departmentSelected: DepartmentDropdownResponse
  ) {
    // remove the selected department if the checkbox is unchecked else pass the selected department to emit function
    if (checked) {
      this.selectedMultiDepartment.push(departmentSelected);
      this.departmentSelected(departmentSelected);
    } else {
      let index = this.selectedMultiDepartment.findIndex(
        (department) => department._id == departmentSelected._id
      );
      if (index > -1) {
        this.selectedMultiDepartment.splice(index, 1);
      }
      this.departmentSelectedEvent.emit(this.selectedMultiDepartment);
    }
  }

  isCheckedUser(id: string) {
    return this.selectedUserIds?.includes(id);
  }
  isCheckedTeam(id: string) {
    return this.selectedTeamIds?.includes(id);
  }
  isCheckedProperty(id: string) {
    return this.selectedPropertytIds?.includes(id);
  }

  isCheckedBrand(id: string) {
    return this.selectedBrandIds.includes(id);
  }

  isCheckedDepartment(id: string) {
    return this.selectedDepartmentIds?.includes(id);
  }

  isCheckedPosition(role: string) {
    return this.selectedPositions?.find(
      (position) => position.position == role
    );
  }

  emitAlreadySelectedUserInfo() {
    if (this.usersList?.length > 0) {
      let userInfos = this.usersList?.filter((user) =>
        this.selectedUserIds?.includes(user.id)
      );
      this.selectedMultiUsers = this.usersList.filter((user) =>
        this.selectedUserIds?.includes(user.id)
      );
      this.alreadySelectedUserInfos.emit(userInfos);
    } else {
      this.alreadySelectedUserInfos.emit([]);
    }
  }

  emitAlreadySelectedTeamInfo() {
    if (this.teamsList?.length > 0) {
      let teamInfos = this.teamsList.filter((team) =>
        this.selectedTeamIds.includes(team.id)
      );
      this.selectedMultiTeam = this.teamsList.filter((team) =>
        this.selectedTeamIds.includes(team.id)
      );
      this.alreadySelectedTeamInfos.emit(teamInfos);
    } else {
      this.alreadySelectedTeamInfos.emit([]);
    }
  }

  emitAlreadySelectedPropertyInfo() {
    if (this.selectedPropertytIds?.length > 0) {
      let propertyInfos = this.propertiesList?.filter((property) =>
        this.selectedPropertytIds?.includes(property._id)
      );
      this.selectedMultiProperty = this.propertiesList?.filter((property) =>
        this.selectedPropertytIds?.includes(property._id)
      );
      this.alreadySelectedPropertyInfos.emit(propertyInfos);
    } else {
      this.selectedMultiProperty = [];
      this.alreadySelectedPropertyInfos.emit([]);
    }
  }

  emitAlreadySelectedPositionInfo() {
    let positionInfo = this.userRoleList.filter((pos) =>
      this.selectedPositionIds?.includes(pos.position)
    );

    this.alreadySelectedPositionInfos.emit(positionInfo);
  }

  emitAlreadySelectedBrandInfo() {
    let brandInfos = this.brandList.filter((brand) =>
      this.selectedBrandIds?.includes(brand.id)
    );
    this.alreadySelectedBrandInfos.emit(brandInfos);
  }

  emitAlreadySelectedDepartmentInfo() {
    if (this.selectedDepartmentIds?.length > 0) {
      let departmentInfos = this.departmentsList?.filter((department) =>
        this.selectedDepartmentIds?.includes(department._id)
      );
      this.selectedMultiDepartment = this.departmentsList?.filter(
        (department) => this.selectedDepartmentIds?.includes(department._id)
      );
      this.alreadySelectedDepartmentInfos.emit(departmentInfos);
    } else {
      this.selectedMultiDepartment = [];
      this.alreadySelectedDepartmentInfos.emit([]);
    }
  }

  getPlaceholderText() {
    if (this.dropDownSwitchType == 'USER') {
      return 'search user...';
    }
    if (this.dropDownSwitchType == 'TEAM') {
      return 'search team...';
    }
    if (this.dropDownSwitchType == 'PROPERTY') {
      return 'search property...';
    }
    if (this.dropDownSwitchType == 'BRAND') {
      return 'search brand...';
    }
    if (this.dropDownSwitchType == 'DEPARTMENT') {
      return 'search department...';
    }
    return '';
  }

  setTempSelectedDataBrand(brandId: string) {
    if (!this.multiple) {
      this.selectedBrandIds = [brandId];
    }
  }

  setTempSelectedDataProperty(propertyId: string) {
    if (!this.multiple) {
      this.selectedPropertytIds = [propertyId];
    }
  }

  setTempSelectedDataUser(userId: string) {
    if (!this.multiple) {
      this.selectedUserIds = [userId];
    }
  }

  setTempSelectedDataTeam(teamId: string) {
    if (!this.multiple) {
      this.selectedTeamIds = [teamId];
    }
  }

  setTempSelectedDataDepartment(departmentId: string) {
    if (!this.multiple) {
      this.selectedDepartmentIds = [departmentId];
    }
  }

  setTempSelectedDataPosition(position: UserPositions) {
    this.selectedPositions = [position];
  }
}
