import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  inject,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import videojs from 'video.js';

@Component({
  selector: 'app-universal-file-viewer',
  templateUrl: './universal-file-viewer.component.html',
  styleUrls: ['./universal-file-viewer.component.scss'],
})
export class UniversalFileViewerComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() url: any;
  @Input() fileName: string = '';
  @Input() title: string = '';
  @Input() canDownload: boolean = false;
  extension: string;
  @Input() multiUrls: string[];
  @Input() baseUrl: string;
  paused = true;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  public currentIndex = 0;
  player: any;
  fileType: string = 'unknown';

  constructor(public modal: NgbActiveModal, private httpClient: HttpClient) {}

  ngOnInit() {
    if (this.fileName) {
      this.extension = this.getFileExtension(this.fileName);
      this.fileType = this.getFileType(this.extension);
      console.log('File Info:', {
        fileName: this.fileName,
        extension: this.extension,
        fileType: this.fileType,
        url: this.url,
      });
    }
  }

  ngAfterViewInit() {
    if (this.isMediaFile(this.extension)) {
      console.log('Initializing media player for:', this.fileType);
      this.initializePlayer();
    }
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  close() {
    if (this.player) {
      this.player.dispose();
    }
    this.modal.dismiss();
  }

  onSlideChange(event): void {
    this.currentIndex = event.current;
  }

  isVideo(extension: string): boolean {
    if (!extension) return false;
    const videoExtensions = ['mp4', 'mov', 'mkv', 'avi', 'webm', 'm4v', 'flv'];
    const isVideo = videoExtensions.includes(extension.toLowerCase());
    console.log('Checking video extension:', extension, isVideo);
    return isVideo;
  }

  isAudio(extension: string): boolean {
    if (!extension) return false;
    const audioExtensions = ['mp3', 'wav', 'ogg', 'm4a', 'aac', 'wma'];
    return audioExtensions.includes(extension.toLowerCase());
  }

  isImage(extension: string): boolean {
    if (!extension) return false;
    const imageExtensions = [
      'jpeg',
      'jpg',
      'png',
      'svg',
      'gif',
      'webp',
      'bmp',
      'tiff',
    ];
    return imageExtensions.includes(extension.toLowerCase());
  }

  isPDF(extension: string): boolean {
    if (!extension) return false;
    return extension.toLowerCase() === 'pdf';
  }

  isDocument(extension: string): boolean {
    if (!extension) return false;
    const documentExtensions = [
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'txt',
      'rtf',
    ];
    return documentExtensions.includes(extension.toLowerCase());
  }

  isMediaFile(extension: string): boolean {
    if (!extension) return false;
    const isMedia = this.isVideo(extension) || this.isAudio(extension);
    console.log('Checking media file:', extension, isMedia);
    return isMedia;
  }

  getFileType(extension: string): string {
    if (!extension) return 'unknown';
    if (this.isVideo(extension)) return 'video';
    if (this.isAudio(extension)) return 'audio';
    if (this.isImage(extension)) return 'image';
    if (this.isPDF(extension)) return 'pdf';
    if (this.isDocument(extension)) return 'document';
    return 'unknown';
  }

  getFileExtension(fileName: string): string {
    if (!fileName) return '';
    const parts = fileName.split('.');
    const ext = parts.length > 1 ? parts[parts.length - 1] : '';
    console.log('Getting extension from:', fileName, 'Result:', ext);
    return ext;
  }

  initializePlayer() {
    if (!this.url) {
      console.log('No URL provided for media player');
      return;
    }

    console.log('Initializing player with:', {
      url: this.url,
      type: this.getMediaType(),
    });

    const options = {
      controls: true,
      autoplay: true,
      preload: 'auto',
      fluid: true,
      responsive: true,
      playbackRates: [0.5, 1, 1.5, 2],
      controlBar: {
        children: [
          'playToggle',
          'volumePanel',
          'currentTimeDisplay',
          'timeDivider',
          'durationDisplay',
          'progressControl',
          'playbackRateMenuButton',
          'fullscreenToggle',
        ],
      },
    };

    this.player = videojs('media-player', options);
    this.player.src({
      type: this.getMediaType(),
      src: this.url,
    });
  }

  getMediaType(): string {
    if (!this.extension) return 'video/mp4';
    if (this.isVideo(this.extension)) {
      return `video/${this.extension}`;
    } else if (this.isAudio(this.extension)) {
      return `audio/${this.extension}`;
    }
    return 'video/mp4'; // fallback
  }

  downloadAttachments() {
    if (!this.url) return;

    if (this.multiUrls?.length == 0 || !this.multiUrls) {
      this.httpClient
        .get(this.url, { responseType: 'blob' })
        .subscribe((res) => {
          const fileName = this.fileName || 'download';
          const downloadUrl = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.target = '_blank';
          link.setAttribute('download', fileName);
          link.click();
          window.URL.revokeObjectURL(downloadUrl);
        });
    } else {
      const currentImageUrl =
        this.baseUrl +
        this.multiUrls[this.currentIndex ? this.currentIndex : 0];
      const link = document.createElement('a');
      link.href = currentImageUrl;
      link.download = `image-${this.currentIndex + 1}`;
      link.click();
    }
  }

  getDocumentIcon(): string {
    if (!this.extension) return 'ti ti-file text-secondary';

    const type = this.extension.toLowerCase();
    if (type.includes('doc')) {
      return 'ti ti-file-text text-primary';
    } else if (type.includes('xls')) {
      return 'ti ti-table text-success';
    } else if (type.includes('ppt')) {
      return 'ti ti-presentation text-warning';
    } else if (type.includes('txt')) {
      return 'ti ti-file-text text-secondary';
    } else {
      return 'ti ti-file text-secondary';
    }
  }
}
