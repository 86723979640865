<span [style.maxWidth.px]="filterWidth">
    <div data-bs-toggle="dropdown" data-container="body" id="dropdownButton" data-bs-display="static">
        <div class="input-icon" *ngIf="!multiple" (click)="setFocusOnInput()">
            <input type="text"
                [ngClass]="{ 'is-valid' : isValid == true , 'is-invalid' : isValid == false && !displaySelectedData}"
                [(ngModel)]="displaySelectedData" class="form-control cursor-pointer dropdown-toggle" readonly
                [placeholder]="placeHolder" [disabled]="disabled" />
            <span class="input-icon-addon">
                <i class="ti ti-selector"></i>
            </span>
        </div>
        <div class="select-container" *ngIf="multiple">
            <div class="value-container">
                <div class="value" *ngFor="let data of displaySelectedArray">
                    <span class="ng-value-label">{{data}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="dropdown-menu " id="dropdownMenu" [style.width.px]="containerWidth" data-bs-container="body">
        <app-user-property-dropdown [multiple]="multiple" [teamOption]="teamOption" [userOption]="userOption"
            [propertyOption]="propertyOption" [brandOption]="brandOption" [departmentOption]="departmentOption"
            [positionOption]="positionOption" [divisionId]="divisionId" [cloud]="cloud" [propertyType]="propertyType" [selectFirstItem]="selectFirstItem"
            [selectedUserIds]="selectedUserIds" [selectedTeamIds]="selectedTeamIds" [excludedUserIds]="excludedUserIds" [selectedPositionIds]="selectedPositionIds"
            [selectedPropertytIds]="selectedPropertytIds" [selectedBrandIds]="selectedBrandIds" [defaultLoadedList]="defaultLoadedList"
            [selectedDepartmentIds]="selectedDepartmentIds" [dropdownHeight]="dropdownHeight" [siteId]="siteId"
            [siteIds]="siteIds" [otherUserOption]="otherUserOption" [hasAllPropertyOption]="hasAllPropertyOption"
            [hasAllBrandOption]="hasAllBrandOption" [department]="department" [hasAllDepartmentOption]="hasAllDepartmentOption"
            [showGeneralInDepartment]="showGeneralInDepartment"
            (userSelectedEvent)="emmitUserSelectedEvent($event)" (teamSelectedEvent)="emmitTeamSelectedEvent($event)"
            (otherSelectedEvent)="emmitOtherSelectedEvent($event)"
            (propertySelectedEvent)="emmitPropertySelectedEvent($event)"
            (brandSelectedEvent)="emmitBrandSelectedEvent($event)"
            (departmentSelectedEvent)="emmitDepartmentSelectedEvent($event)"
            (positionSelectedEvent)="emmitPositionSelectedEvent($event)"
            (alreadySelectedPropertyInfos)="setDisplayDataProperty($event)"
            (alreadySelectedUserInfos)="setDisplayDataUser($event)"
            (alreadySelectedTeamInfos)="setDisplayDataTeam($event)"
            (alreadySelectedBrandInfos)="setDisplayDataBrand($event)"
            (alreadySelectedDepartmentInfos)="setDisplayDataDepartment($event)"
            (alreadySelectedPositionInfos)="setDisplayDataPosition($event)"></app-user-property-dropdown>    
    </div>
</span>