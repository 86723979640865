import { Status } from '../../user/models/user.models';

export class Department {
  public name: string;
  public description: string;
  public companyId: string;
  public siteId: string;
  public facilityId: string;
  public managerId: string;
  public managerName?:string;
  public parentId: string;
  public status: Status;
  public addedOn: number;
  public lastUpdated: number;
  public Id: string;
  public id: string;
  public _id: string;
  public displayName :string;
}

export class ListDepartmentRequest {
  public companyId: string;
  public status: Status[] = [Status.ACTIVE];
  public nameContains: string;
  public siteIds: string[];
  public currentPage?: number = 1;
  public rowsPerPage?: number = 10;
  public sort:string='addedOn';
}


export enum DepartmentName {
  MANAGEMENT = 'MANAGEMENT',
  HOUSEKEEPING = 'HOUSEKEEPING',
  FNB_SERVICE = 'FNB_SERVICE',
  FNB_PRODUCTION = 'FNB_PRODUCTION',
  FINANCE = 'FINANCE',
  FRONT_OFFICE = 'FRONT_OFFICE',
  ENGINEERING = 'ENGINEERING',
  IT = 'IT',
  MARKETING = 'MARKETING',
  QUALITY = 'QUALITY',
  PURCHASE = 'PURCHASE',
  SALES = 'SALES',
  SECURITY = 'SECURITY',
  AYURVEDA = 'AYURVEDA',
  HR = 'HR',
  NATUROPATHY = 'NATUROPATHY',
  SPA = 'SPA',
  NATURALIST = 'NATURALIST',
  EXPERIENCE = 'EXPERIENCE',
  HAPPY_HUB = 'HAPPY_HUB',
  SVAASTHA = 'SVAASTHA'
}