import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Role } from '../models/common.models';
import { Router } from '@angular/router';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentSession = this.authService.getSession();
    if (!currentSession) {
      return next.handle(request);
    }
    if (request.body instanceof FormData) {
      var req = request.clone({
        setHeaders: {
          sessionId: currentSession?.sessionId,
          'X-XSRF-TOKEN': currentSession?.userToken,
          userId: currentSession?.userId,
        },
      });
    } else {
      var req = request.clone({
        setHeaders: {
          sessionId: currentSession?.sessionId,
          'X-XSRF-TOKEN': currentSession?.userToken,
          userId: currentSession?.userId,
          'Content-Type': 'application/json',
        },
      });
    }
    if (request.context.get(BYPASS_NON_INJECTION) === true) {
      req = request.clone({
        setHeaders: {},
      });
    }
    if (request.context.get(BYPASS_INJECTION) === true) return next.handle(req);
    if (req.method == 'GET') return next.handle(req);

    if (!req.body?.companyId) {
      req.body['companyId'] = currentSession?.companyId;
    }
    if (!req.body?.siteId) {
      if (
        currentSession.role === Role.PROPERTY_USER &&
        currentSession.currentProfileId
      ) {
        req.body.siteId = currentSession.currentProfileId;
      } else {
        req.body.siteId = currentSession.siteIds
          ? currentSession.siteIds[0]
          : undefined;
      }
    }
    if (!req.body?.facilityId) {
      if (
        currentSession.role === Role.FACILITY_USER &&
        currentSession.currentProfileId
      ) {
        req.body.facilityId = currentSession.currentProfileId;
      } else {
        req.body.facilityId = currentSession.facilityIds
          ? currentSession.facilityIds[0]
          : undefined;
      }
    }
    let ok: string;
    return next.handle(req);
  }
}
export const BYPASS_BODY_INJECTION = new HttpContextToken(() => false);
export const BYPASS_INJECTION = new HttpContextToken(() => false);
export const BYPASS_NON_INJECTION = new HttpContextToken(() => false);
