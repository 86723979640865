<div class="dropzone dz-clickable" id="dropzone-default" action="../" autocomplete="off" novalidate=""
    (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
    <div class="dz-default dz-message">
        <button *ngIf="!selectedFilePreview" class="dz-button" type="button" (click)="onButtonClick()">
            Drop files here to upload
        </button>

        <div *ngIf="selectedFilePreview && !multiple"
            class="dz-preview dz-processing dz-image-preview dz-error dz-complete">
            <div class="dz-image">
                <img data-dz-thumbnail="" alt="" width="100" [src]='selectedFilePreview'
                    *ngIf="!selectedFileType || selectedFileType === 'image'">
                <img data-dz-thumbnail="" alt="" width="100" style="object-fit: contain !important;"
                    src="assets/images/announcement/pdf.png" *ngIf="selectedFileType==='pdf' && selectedFileName">
                <i class="ti ti-trash-x-filled large" (click)="removeSelectedFile()"></i>

                <!-- <svg (click)="removeSelectedFileMulti(i)" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash-x-filled large" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16zm-9.489 5.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" stroke-width="0" fill="currentColor"></path>
                        <path d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z" stroke-width="0" fill="currentColor"></path>
                </svg> -->
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size=""><strong>{{selectedFileSize}}</strong></span></div>
                    <div class="dz-filename"><span data-dz-name="">{{selectedFileName}}</span></div>
                </div>
            </div>

            <!-- <div class="dz-image">
                <img data-dz-thumbnail="" alt="" width="100" 
                    [src]=selectedFilePreview>
                    <svg (click)="removeSelectedFile()" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash-x-filled large" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16zm-9.489 5.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" stroke-width="0" fill="currentColor"></path>
                        <path d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z" stroke-width="0" fill="currentColor"></path>
                     </svg>
                   
            </div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size=""><strong>{{selectedFileSize}}</strong></span></div>
                <div class="dz-filename"><span data-dz-name="">{{selectedFileName}}</span></div>
            </div> -->
        </div>
        <div *ngIf="selectedFilePreviewMulti.length!=0&&multiple && !isadvancedMulti"
            class="dz-preview dz-processing dz-image-preview dz-error dz-complete">
            <div class="row">
                <ng-container *ngFor="let file of selectedFilePreviewMulti;let i = index;">
                    {{file | json}}
                    <div class="col-sm-3">
                        <div class="dz-image">
                            <img *ngIf="file.type==='image'" alt="" width="100" [src]=file.preview>
                            <i class="ti ti-trash-x-filled large" (click)="removeSelectedFileMulti(i)"></i>

                            <div class="dz-details">
                                <div class="dz-size"><span data-dz-size=""><strong>{{file.size}}</strong></span></div>
                                <div class="dz-filename"><span data-dz-name="">{{file.name}}</span></div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <input type="file" #fileInput1 style="display:none" [accept]="acceptType()" (change)="onFileSelected($event)">
    <input type="file" #fileInput2 style="display:none" [accept]="acceptType()"
        (change)="onFileSelectedMultiple($event)" [multiple]="multiple">
</div>