import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import StringUtils from '../../Utils/stringUtils';
import { UniversalFileViewerComponent } from '../universal-file-viewer/universal-file-viewer.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-file-thumbnail',
  templateUrl: './file-thumbnail.component.html',
  styleUrls: ['./file-thumbnail.component.scss'],
})
export class FileThumbnailComponent implements OnInit, OnChanges {
  @Input() fileName: string;
  @Input() imagePath: string;
  @Input() imageUrl: string;

  @Input() removable: boolean = true;
  @Input() viewable: boolean = true;
  @Input() height: string = '20px';
  @Input() width: string = '20px';
  @Input() styleClasses: string[];
  @Output() imageRemove = new EventEmitter<any>();
  @Output() downloadClicked = new EventEmitter<any>();

  public modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}
  ngOnChanges(changes: SimpleChanges) {
    const imagePath = changes['imagePath'];
    const imageUrl = changes['imageUrl'];
    const fileName = changes['fileName'];
    const removable = changes['removable'];
    const viewable = changes['viewable'];
    if (imagePath) this.imagePath = imagePath.currentValue;
    if (imageUrl) {
      this.imageUrl = imageUrl.currentValue;
    }
    if (fileName) this.fileName = fileName.currentValue;
    if (removable) this.removable = removable.currentValue;
    if (viewable) this.viewable = viewable.currentValue;
  }

  ngOnInit(): void {}

  viewAttachments() {
    if (this.viewable) {
      if (
        [
          'pdf',
          'jpeg',
          'jpg',
          'png',
          'svg',
          'gif',
          'mp4',
          'MOV',
          'MKV',
          'AVI',
          'webm',
        ].includes(this.getFileExtension())
      ) {
        console.log('here');

        this.modalRef = this.modalService.open(UniversalFileViewerComponent, {
          size: 'xl',
          centered: true,
          scrollable: true,
        });
        const componentInstance: UniversalFileViewerComponent =
          this.modalRef.componentInstance;
        componentInstance.url = this.imageUrl;
        componentInstance.extension = this.getFileExtension();
        componentInstance.fileName = this.fileName;
        componentInstance.title = this.fileName;

        this.modalRef.result
          .then((result) => {
            if (result === '') {
            }
          })
          .catch((reason) => {});
      } else {
        this.downloadDocument();
      }
    }
  }

  downloadDocument() {
    this.downloadClicked.emit(this.imagePath);
  }

  getFileExtension() {
    return StringUtils.getFileExtension(this.imagePath);
  }

  getFileImage() {
    let extension = this.getFileExtension();
    if (['jpg', 'jpeg', 'png', 'gif', 'tiff', 'svg'].includes(extension)) {
      return this.imageUrl;
    }
    return StringUtils.getFileIcon(extension);
  }

  removeImage() {
    this.imageRemove.emit(this.imagePath);
  }

  onClick(event: Event) {
    event.stopPropagation();
    // Your existing logic here...
  }
}
