import { HomeComponent } from './../../modules/home/pages/home/home.component';
import { Issue } from './../../modules/feedback/models/issue.models';
import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';

export const AuthRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('src/app/modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    loadChildren: () =>
      import('src/app/modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('src/app/modules/feedback/feedback.module').then(
        (m) => m.FeedbackModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'reviews',
    loadChildren: () =>
      import('src/app/modules/reviews/reviews.module').then(
        (m) => m.ReviewsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'guest-experience',
    loadChildren: () =>
      import('src/app/modules/guest/guest.module').then((m) => m.GuestModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'departments',
    loadChildren: () =>
      import('src/app/modules/department/department.module').then(
        (m) => m.DepartmentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'lost-and-found',
    loadChildren: () =>
      import('src/app/modules/lost-and-found/lost-found.module').then(
        (m) => m.LostFoundModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('src/app/modules/document/document.module').then(
        (m) => m.DocumentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'campaign',
    loadChildren: () =>
      import('src/app/modules/campaign/campaign.module').then(
        (m) => m.CampaignModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('src/app/modules/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('src/app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import(
        'src/app/modules/template-management/template-management.module'
      ).then((m) => m.TemplateManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'task',
    loadChildren: () =>
      import('src/app/modules/task//task.module').then((m) => m.TaskModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'loyalty',
    loadChildren: () =>
      import('src/app/modules/loyalty/loyalty.module').then(
        (m) => m.LoyaltyModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'goals',
    loadChildren: () =>
      import('src/app/modules/goals/goals.module').then((m) => m.GoalsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'log-management',
    loadChildren: () =>
      import('src/app/modules/log-management/log-management.module').then(
        (m) => m.LogManagementModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    loadChildren: () =>
      import('src/app/modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'segments',
    loadChildren: () =>
      import('src/app/modules/segments/segments.module').then(
        (m) => m.SegmentsModule
      ),
  },
  {
    path: 'work-order',
    loadChildren: () =>
      import('src/app/modules/work-order/work-order.module').then(
        (m) => m.WorkOrderModule
      ),
  },
  {
    path: 'housekeeping',
    loadChildren: () =>
      import('src/app/modules/housekeeping/housekeeping.module').then(
        (m) => m.HousekeepingModule
      ),
  },
  {
    path: 'escalation',
    loadChildren: () =>
      import('src/app/modules/escalation/escalation.module').then(
        (m) => m.EscalationModule
      ),
  },
  {
    path: 'crm',
    loadChildren: () =>
      import('src/app/modules/guest-crm/guest-crm.module').then(
        (m) => m.GuestCrmModule
      ),
  },
  {
    path: 'audit',
    loadChildren: () =>
      import('src/app/modules/audit/audit.module').then((m) => m.AuditModule),
  },
  {
    path: 'meetings',
    loadChildren: () =>
      import(
        'src/app/modules/minutes-of-meeting/minutes-of-meeting.module'
      ).then((m) => m.MinutesOfMeetingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'scheduler',
    loadChildren: () =>
      import('src/app/modules/scheduler/scheduler.module').then(
        (m) => m.SchedulerModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'budget',
    loadChildren: () =>
      import('src/app/modules/budget/budget.module').then(
        (m) => m.BudgetModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'configuration-settings',
    loadChildren: () =>
      import(
        'src/app/modules/work-order-settings/work-order-settings.module'
      ).then((m) => m.WorkOrderSettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'divisions',
    loadChildren: () =>
      import('src/app/modules/divisions/divisions.module').then(
        (m) => m.DivisionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'announcements',
    loadChildren: () =>
      import('src/app/modules/announcements/announcements.module').then(
        (m) => m.AnnouncementsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'gate-pass',
    loadChildren: () =>
      import('src/app/modules/gate-pass/gate-pass.module').then(
        (m) => m.GatePassModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'gms',
    loadChildren: () =>
      import('src/app/modules/gms/gms.module').then((m) => m.GmsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'regulatory-compliances',
    loadChildren: () =>
      import(
        'src/app/modules/regulatory-compliances/regulatory-compliances.module'
      ).then((m) => m.RegulatoryCompliancesModule),
  },
  {
    path: 'ppm-cycle',
    loadChildren: () =>
      import('src/app/modules/ppm/ppm.module').then((m) => m.PmsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'checklist',
    loadChildren: () =>
      import('src/app/modules/checklist/checklist.module').then(
        (m) => m.ChecklistModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'ird-register',
    loadChildren: () =>
      import('src/app/modules/ird-register/ird-register.module').then(
        (m) => m.IrdRegisterModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path : 'property-management',
    loadChildren: ()=> import('src/app/modules/property-management/property-management.module').then(
      (m) => m.PropertyManagementModule
    )
  },
  {
    path: 'asset-management',
    loadChildren: () =>
      import('src/app/modules/asset-management/asset-management.module').then(
        (m) => m.AssetManagementModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'property-information',
    loadChildren: () =>
      import('src/app/modules/property-information/property-information.module').then(
        (m) => m.PropertyInformationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'roster-manager',
    loadChildren: () =>
      import('src/app/modules/roster-manager/roster-manager.module').then(
        (m) => m.RosterManagerModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];
