import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { MinutesOfMeetingService } from 'src/app/modules/minutes-of-meeting/services/minutes-of-meeting.service';

export class OtherRequest {
  name: string;
  phoneNumber: string;
  email: string;
}

@Component({
  selector: 'app-common-others-modal',
  templateUrl: './common-others-modal.component.html',
  styleUrls: ['./common-others-modal.component.scss'],
})
export class CommonOthersModalComponent {
  public loading = false;
  @Input() momId: number;

  public payload = new OtherRequest();
  constructor(
    public modal: NgbActiveModal,
    private authService: AuthService,
    private momService: MinutesOfMeetingService,
    private toastr: ToastrService
  ) {}

  validate() {
    if ((this.payload.name && this.payload.phoneNumber) || this.payload.email) {
      this.addParticipants();
    } else {
      this.toastr.error('Please enter email or phone  number');
    }
  }

  addParticipants() {
    this.modal.close(this.payload);
  }
}
