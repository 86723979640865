import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Department, ListDepartmentRequest } from '../models/department.models';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';
import { ApiPaginatedResponse, ApiResponse, PaginatedWebData } from 'src/app/core/models/common.models';
import { catchError, EMPTY, map, Observable, of, shareReplay, tap } from 'rxjs';
import { Vendor } from '../../work-order-settings/models/workorderSettings.models';
import { SessionUtils } from 'src/app/shared/Utils/sessionUtils';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends BaseService{
private inProgressRequests: { [departmentId: string]: Observable<Department> } = {};
  constructor(private http: HttpClient) { 
    super();
  }
  addDepartment(request: Department) {
    return this.http.post<any>(`${this.gemURL}/department`, request);
  }

  listDepartment(request: ListDepartmentRequest) {
    return this.http.post<ApiResponse<Department[]>>(`${this.gemURL}/department/dropdown`, request);
  }

  updateDepartment(request: Department) {
    return this.http.put<ApiResponse<Department[]>>(`${this.gemURL}/department`, request);
  }

  deleteDepartment(request: Department) {
    return this.http.delete<ApiResponse<Department[]>>(`${this.gemURL}/department`,{body:request});
  }

  getDepartment(departmentId:string) {
    return this.http.get<ApiResponse<Department>>(`${this.gemURL}/department/${departmentId}`);
  }

  filterListDepartment(request: ListDepartmentRequest) {
    return this.http.post<ApiPaginatedResponse< Department[]>>(`${this.gemURL}/department/search`, request);
  }

    getDepartmentInfoCached(departmentId: string) {
      // Check if there is already a request in progress for this DepartmentId
      if (this.inProgressRequests[departmentId]) {
        return this.inProgressRequests[departmentId];
      }
      const type = 'DEPARTMENT'; // Define the cache type for Department data
      const cachedData = SessionUtils.getFromSessionStorage(type, departmentId);
  
      // If data is available in session storage, return it as an observable
      if (cachedData) {
        return of(cachedData);
      }
  
      // Make the HTTP call to fetch Department data
      const request = this.http
        .get<ApiResponse<Department>>(`${this.gemURL}/department/${departmentId}`)
        .pipe(
          // Perform side effects: Cache the response
          tap((response) => {
            SessionUtils.setToSessionStorage(type, departmentId, response.data);
          }),
          // Handle errors: Remove the in-progress request and return an EMPTY observable
          catchError((error) => {
            console.error(`Error fetching ${type} details: ${error}`);
            delete this.inProgressRequests[departmentId];
            return EMPTY;
          }),
          // Map the API response to just the data
          map((response) => response.data),
          // Share the observable among multiple subscribers
          shareReplay(1)
        );
  
      // Cache the in-progress request
      this.inProgressRequests[departmentId] = request;
  
      return request;
    }

}
