<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between">
      <h4>{{title}}</h4>
    </div>
    <div>
      <button *ngIf="canDownload" class="btn" (click)="downloadAttachments()">
        <i class="ti ti-download"></i>
      </button>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
  </div>
  <div class="modal-body">
    <!-- PDF Viewer -->
    <div *ngIf="fileType === 'pdf'" class="pdf-container">
      <pdf-viewer [src]="url" style="width: 100%; height: 80vh;"></pdf-viewer>
    </div>

    <!-- Image Viewer -->
    <div class="img-container" *ngIf="fileType === 'image'">
      <div class="img-wrapper">
        <img [src]="url" [alt]="fileName">
      </div>
    </div>

    <!-- Multi Image Carousel -->
    <div class="multi-img-container" *ngIf="multiUrls?.length > 0">
      <ngb-carousel
        #carousel
        [pauseOnHover]="pauseOnHover"
        [pauseOnFocus]="pauseOnFocus"
        (slide)="onSlideChange($event)">
        <ng-template ngbSlide *ngFor="let url of multiUrls; let i = index" [id]="i.toString()">
          <div class="picsum-img-wrapper">
            <img [src]="baseUrl + url" alt="Image Slide" />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <!-- Video/Audio Player -->
    <div class="media-container" *ngIf="fileType === 'video' || fileType === 'audio'">
      <video id="media-player" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto">
        <source [src]="url" [type]="getMediaType()">
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
        </p>
      </video>
    </div>

    <!-- Document Viewer -->
    <div class="document-container" *ngIf="fileType === 'document'">
      <div class="document-preview">
        <i [class]="getDocumentIcon()"></i>
        <p class="document-name">{{fileName}}</p>
        <p class="document-info">This file type cannot be previewed directly.</p>
        <p class="document-info">Please download to view the content.</p>
      </div>
    </div>

    <!-- Unknown File Type -->
    <div class="unknown-container" *ngIf="fileType === 'unknown'">
      <div class="unknown-preview">
        <i class="ti ti-file text-secondary"></i>
        <p class="file-name">{{fileName}}</p>
        <p class="file-info">This file type cannot be previewed directly.</p>
        <p class="file-info">Please download to view the content.</p>
      </div>
    </div>
  </div>
</div>