<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">        
            <i class="ti ti-user fs-2 fw-bold"></i>
             <span
                class="ms-2">Participant Details</span></h5>
        <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
    </div>
    <form #publishForm="ngForm">
        <div class="modal-body p-0">

            <div class="p-3">
                <div class="row mb-3" >
                    <div class="col-lg-12">
                        <label class="form-label required">Name</label>
                        <input type="text" #name="ngModel" required [(ngModel)]="payload.name"  class="form-control" name="name" placeholder="Enter Participant Name" >

                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-12">
                        <label class="form-label">Email</label>
                        <input type="email" #email="ngModel" [(ngModel)]="payload.email"  class="form-control" name="email" placeholder="Enter Participant Email">

                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-12">
                        <label class="form-label">Phone</label>
                        <input type="number" #phone="ngModel" [(ngModel)]="payload.phoneNumber"   class="form-control" name="phone" placeholder="Enter Participant Phone Number">

                    </div>
                </div>
                
                
            </div>
            <div class="modal-footer" >
                <button type="button" class="btn" (click)="modal.dismiss()">Cancel</button>
                <button class="btn btn-primary" [disabled]="publishForm.invalid" (click)="validate()">
                    Add Participant
                </button>
            </div>
        </div>
    </form>
</div>