import { Directive, OnInit } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as moment from 'moment';

// Custom adapter that ensures proper Moment object handling
export class SafeMomentDateAdapter extends MomentDateAdapter {
  override parse(value: any, parseFormat: string): moment.Moment | null {
    if (value) {
      // Ensure we're working with a valid moment object
      return moment(value, parseFormat, true).isValid()
        ? moment(value, parseFormat)
        : null;
    }
    return null;
  }

  override isValid(date: moment.Moment): boolean {
    // Ensure the date is a moment object before calling clone
    return moment.isMoment(date) && date.isValid();
  }

  override clone(date: moment.Moment): moment.Moment {
    // Ensure the date is a moment object before calling clone
    return moment.isMoment(date) ? date.clone() : moment(date);
  }
}

export const DAY_MONTH_YEAR_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Directive({
  selector: '[appYearMonthDate]',
  providers: [
    {
      provide: DateAdapter,
      useClass: SafeMomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DAY_MONTH_YEAR_FORMATS },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: false, strict: false },
    },
  ],
})
export class YearMonthDateDirective implements OnInit {
  constructor(private dateAdapter: DateAdapter<moment.Moment>) {}

  ngOnInit() {
    // Ensure moment adapter is properly initialized
    this.dateAdapter.setLocale('en');
  }
}
