import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
  
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class YearPickerComponent implements OnInit{
  
  @Input() defaultYear;
  @Output() yearSelectedEvent = new EventEmitter<any>();
  year: Moment = moment();
  selectedYear = ''

  ngOnInit(): void {
    if(this.defaultYear){
      this.year = moment().year(this.defaultYear);
    }
  }
  chosenYearHandler(normalizedYear: Moment, dp: any) {
      this.year = moment(this.year).year(normalizedYear.year()); // Update year
      dp.close();
      this.selectedYear = this.year.format('YYYY');
      this.yearSelectedEvent.emit(this.selectedYear);
      
    }
}
