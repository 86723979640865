import { Pipe, PipeTransform } from '@angular/core';
import StringUtils from '../Utils/stringUtils';

@Pipe({
  name: 'displayText',
  pure: true,
})
export class DisplayTextPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return StringUtils.getDisplayNameString(value);
  }
}
