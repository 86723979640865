import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Department } from 'src/app/modules/department/models/department.models';
import { DepartmentService } from 'src/app/modules/department/services/department.service';

@Component({
  selector: 'app-department-info',
  templateUrl: './department-info.component.html',
  styleUrls: ['./department-info.component.scss']
})
export class DepartmentInfoComponent implements OnChanges{
  @Input() departmentId : string;
  public department : Department

  constructor(private departmentService : DepartmentService){}

  ngOnChanges(changes: SimpleChanges): void {
    this.getDepartmentInfo();
  }

  getDepartmentInfo(){
    if(this.departmentId){
      this.departmentService.getDepartmentInfoCached(this.departmentId).subscribe({
        next:(res)=>{
          this.department = res;
          
        },
        error:(err)=>{
          console.log(err);
          
        }
      })
    }
    
  }

}
