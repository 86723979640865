import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { MinutesOfMeeting, MinutesOfMeetingCreateRequest, MinutesOfMeetingFilter, MinutesOfMeetingNote, MinutesOfMeetingNotesRequest, MinutesOfMeetingParticipant, MinutesOfMeetingStatusUpdateRequest, MinutesOfMeetingTopicsRequest, MinutesofMeetingParticipantsAddRequest, MomMailRequest } from '../models/minutes-of-meeting.models';
import { HkPaginatedResponse, HkResponse } from 'src/app/core/models/common.models';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';

@Injectable({
  providedIn: 'root'
})
export class MinutesOfMeetingService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  createMOM(payload: MinutesOfMeetingCreateRequest) {
    return this.http.post<HkResponse<MinutesOfMeeting>>(`${this.logBook}api/minutes-of-meeting`, payload)
  }
  getMOM(momId: number) {
    return this.http.get<HkResponse<MinutesOfMeeting>>(`${this.logBook}api/minutes-of-meeting/${momId}`)
  }

  searchMOM(payload: MinutesOfMeetingFilter) {
    return this.http.post<HkPaginatedResponse<MinutesOfMeeting[]>>(`${this.logBook}api/minutes-of-meeting/search`, payload)
  }

  addParticipants(payload: MinutesofMeetingParticipantsAddRequest) {
    return this.http.post<HkResponse<MinutesOfMeetingParticipant>>(`${this.logBook}api/minutes-of-meeting-participants`, payload)
  }
  getParticipants(momId: number) {
    return this.http.get<HkResponse<MinutesOfMeetingParticipant[]>>(`${this.logBook}api/minutes-of-meeting-participants/${momId}/participants`)
  }

  deleteParticipant(id: number) {
    return this.http.delete<HkResponse<string>>(`${this.logBook}api/minutes-of-meeting-participants/${id}`, { context: new HttpContext().set(BYPASS_INJECTION, true) })
  }

  deleteTopic(id: number) {
    return this.http.delete<HkResponse<string>>(`${this.logBook}api/minutes-of-meeting-topics/${id}`, { context: new HttpContext().set(BYPASS_INJECTION, true) })
  }

  deleteNote(id: number) {
    return this.http.delete<HkResponse<string>>(`${this.logBook}api/minutes-of-meeting-notes/${id}`, { context: new HttpContext().set(BYPASS_INJECTION, true) })
  }

  updateNote(id: number, notePayload: any) {
    return this.http.patch<HkResponse<MinutesOfMeetingNote>>(`${this.logBook}api/minutes-of-meeting-notes/${id}`, notePayload)
  }

  addNote(payload: MinutesOfMeetingNotesRequest) {
    return this.http.post<HkResponse<MinutesOfMeetingNote>>(`${this.logBook}api/minutes-of-meeting-notes`, payload)
  }
  getNote(momId: number) {
    return this.http.get<HkResponse<MinutesOfMeetingNote[]>>(`${this.logBook}api/minutes-of-meeting-notes/${momId}/notes`)
  }

  addTopic(payload: MinutesOfMeetingTopicsRequest) {
    return this.http.post<HkResponse<MinutesOfMeetingNote>>(`${this.logBook}api/minutes-of-meeting-topics`, payload)
  }
  getTopics(momId: number) {
    return this.http.get<HkResponse<MinutesOfMeetingNote[]>>(`${this.logBook}api/minutes-of-meeting-topics/${momId}/topics`)
  }

  addMOMAgenda(momId: number, mom: MinutesOfMeetingCreateRequest) {
    return this.http.patch<HkResponse<MinutesOfMeeting>>(`${this.logBook}api/minutes-of-meeting/${momId}/agenda`, mom)
  }
  changeMOMStatus(momId: number, payload: MinutesOfMeetingStatusUpdateRequest) {
    return this.http.patch<HkResponse<MinutesOfMeeting>>(`${this.logBook}api/minutes-of-meeting/${momId}/status`, payload)
  }

  getMomSummery(momId: number) {
    return this.http.patch<HkResponse<string>>(`${this.logBook}api/minutes-of-meeting/${momId}/summary`, { id: momId })
  }

  deleteMeeting(meetingId: number) {
    return this.http.delete<HkResponse<string>>(`${this.logBook}api/minutes-of-meeting/${meetingId}`, { context: new HttpContext().set(BYPASS_INJECTION, true) })
  }

  _sendMail(meetingId: number, payload: MomMailRequest) {
    return this.http.post<HkResponse<any>>(`${this.logBook}api/minutes-of-meeting/${meetingId}/email`, payload)
  }
}
