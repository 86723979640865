import { CurrencyPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SessionUtils } from '../Utils/sessionUtils';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Pipe({
  name: 'dynamicCurrency',
})
@Injectable({ providedIn: 'root' })
export class DynamicCurrencyPipe implements PipeTransform {
  private currencyPipe: CurrencyPipe;
  constructor(private authService: AuthService) {
    this.currencyPipe = new CurrencyPipe('en-IN');
  }

  transform(
    value: number | string,
    digitsInfo?: string,
    locale: string = 'en-IN'
  ): string | null {
    const storedData = this.authService.getEntityProfile(); // Replace with actual key
    let currency = 'INR'; // Default to INR

    if (storedData) {
      try {
        // currency = storedData?.data?.currency || 'INR';
      } catch (error) {
        console.error('Error parsing currency from localStorage', error);
      }
    }
    return this.currencyPipe.transform(
      value,
      currency,
      'symbol',
      digitsInfo,
      locale
    );
  }
}
